import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CookiesPolicy = () => (
  <Layout>
    <SEO title="Cookies Policy" />

    <section className="default-top">
      <div className="container">
        <h2>Cookies Policy</h2>
      </div>
    </section>

    <section className="default-content">
      <div className="container">
        <h4>What are cookies?</h4>
        <p>
          An internet or web cookie, referred to as a 'cookie', is a small piece
          of data that web servers pass along to your web browser. The cookie is
          used by your web browser while you browse through a site.
        </p>
        <p>
          There are two types of cookies that websites typically use: functional
          cookies and advertising cookies. The uShip website uses both types of
          cookies. The first is a necessary cookie that helps our site function
          properly. From creating your listing to releasing payments, these
          cookies ensure your delivery transaction goes as smoothly as possible.
          The other cookie types we use allow us to advertise, reach new
          audiences, and connect with users beyond our website. For
          transparency, we have detailed these cookies below.
        </p>
      </div>
    </section>
  </Layout>
)

export default CookiesPolicy
